import React from "react";
import classes from "./Header.module.css";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";

export default function Headers(props) {
  // const [IsHovering, SetIsHovering] = useState(true);

  // const handleEnterMouse = (event) => {
  //   SetIsHovering(!IsHovering);
  // };

  const scrollToContactUs = function () {
    document.getElementById("ContactUs").scrollIntoView({ behavior: "smooth" });
  };

  return (
    <section className={classes.Header} id="Header" ref={props.reference}>
      <div className={classes.TitleBox}>
        <h2 className={classes.Title}>
          <Typography>
            <span>Shadow Interiors</span>
          </Typography>
        </h2>
        <Typography variant="h4" color="#FFF" className={classes.SubTitle}>
          Elegance Your Way
        </Typography>

        <Button
          variant="outlined"
          className={classes.HeaderButton}
          color="primary"
          onClick={scrollToContactUs}
          disableRipple
        >
          Book Design Consulation
        </Button>
      </div>
    </section>
  );
}
