import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Typography } from "@mui/material";
import classes from "./AboutUs.module.css";
import style from "../Content/Content.module.css";
import CEOImage from "../../images/Vikas.jpeg";
import CoFounderImage from "../../images/Shreya.jpg";
import { faQuoteLeftAlt, faQuoteRightAlt } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faGoogle, faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import Notification from "../Notification/Notification";

export default function AboutUs(props) {
  const SocialMediaAccounts = [
    {
      name: "Instagram",
      link: "https://www.instagram.com/shadow_interior_pune/",
    },
    {
      name: "Google",
      link: "shadowinterior2129@gmail.com",
    },
    {
      name: "LinkedIn",
      link: "shadowinterior2129@gmail.com",
    },
    {
      name: "Facebook",
      link: "shadowinterior2129@gmail.com",
    },
  ];

  const [Notify, SetNotify] = useState({
    isOpen: false,
    message: "",
    type: "info",
    title: "Information",
  });

  const handleRedirect = (event) => {
    const name = event.currentTarget.id;

    const site = SocialMediaAccounts.find((e) => name === e.name);

    if (name === "Google") {
      navigator.clipboard.writeText(site.link);
      SetNotify({
        isOpen: true,
        message: "Email id copied to clipboard",
        type: "info",
        title: "Copied",
      });
    } else {
      window.open(site.link, "_blank");
    }
  };

  const IconFont = 25;

  return (
    <section className={classes.AboutUs} id="AboutUs" ref={props.reference}>
      <div className={style.TitleHolder}>
        <h3 className={style.Title} id="Title">
          About Us
        </h3>
      </div>
      <div className={classes.ContainerBox}>
        <div className={classes.SubSection1}>
          <div className={classes.PersonBox}>
            <div className={classes.PersonInfo}>
              <Avatar className={classes.GITicons} src={CEOImage} alt="Vikas Kendre" />
              <Typography variant="h5" className={classes.PersonName}>
                Vikas Kendre
              </Typography>
              <Typography variant="subtitle2" className={classes.PersonPost}>
                Founder & CEO
              </Typography>
            </div>
            <div className={classes.PersonInfo}>
              <Avatar className={classes.GITicons} src={CoFounderImage} alt="Vikas Kendre" />
              <Typography variant="h5" className={classes.PersonName}>
                Shreya Kendre
              </Typography>
              <Typography variant="subtitle2" className={classes.PersonPost}>
                Co-Founder & MD
              </Typography>
            </div>
          </div>
          <div className={classes.Quote}>
            <Typography variant="subtitle2">
              <FontAwesomeIcon icon={faQuoteLeftAlt} className={classes.startQuote} />
              The essence of interior design will always be about people and how they live. It is about the realities of
              what makes for an attractive, civilized, meaningful environment, not about fashion or what’s in or what’s
              out. This is not an easy job.
              <FontAwesomeIcon icon={faQuoteRightAlt} className={classes.endQuote} />
              <br />
              <br />
              <em>
                –<b> Albert Hadley</b>
              </em>
            </Typography>
          </div>
          <div className={classes.SocialContacts}>
            <FontAwesomeIcon
              id="Google"
              icon={faGoogle}
              fontSize={IconFont}
              className={classes.Google}
              onClick={(event) => handleRedirect(event)}
              target="_blank"
            />
            <FontAwesomeIcon
              id="Facebook"
              icon={faFacebook}
              fontSize={IconFont}
              className={classes.Facebook}
              onClick={(event) => handleRedirect(event)}
              target="_blank"
            />
            <FontAwesomeIcon
              id="Instagram"
              icon={faInstagram}
              fontSize={IconFont}
              className={classes.Instagram}
              onClick={(event) => handleRedirect(event)}
              target="_blank"
            />
            <FontAwesomeIcon
              id="LinkedIn"
              icon={faLinkedin}
              fontSize={IconFont}
              className={classes.LinkedIn}
              onClick={(event) => handleRedirect(event)}
              target="_blank"
            />
          </div>
        </div>
      </div>
      <Notification SetNotify={SetNotify} Notify={Notify}></Notification>
    </section>
  );
}
