// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAqDS9gQIwF3MXMpT5pf-tAmlPYz420E7c",
  authDomain: "shadow-interiors.firebaseapp.com",
  projectId: "shadow-interiors",
  storageBucket: "shadow-interiors.appspot.com",
  messagingSenderId: "397071052123",
  appId: "1:397071052123:web:fc2c1504c8add522363e7e",
  measurementId: "G-G9M3H5Q6VY",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

//Export FireBaseDB
export const FireBaseDB = getFirestore(app);
