import { React, useRef, useState } from "react";
import style from "../Content/Content.module.css";
import classes from "./ContactUs.module.css";
import { Avatar, Button, FormControl, TextField, InputAdornment, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FireBaseDB } from "../../Server/Firebase";
import { collection, addDoc } from "firebase/firestore";
import { MuiTelInput, isValidPhoneNumber } from "mui-tel-input";
import { faCircleRight, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faCircleCheck, faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import Notification from "../Notification/Notification";

export default function ContactUs(props) {
  const [contactNumber, setcontactNumber] = useState("");

  const [isValidNumber, setIsValidNumber] = useState(false);

  const [isValidEmail, setIsValidEmail] = useState(false);

  const [isMailEntered, setIsMailEntered] = useState(false);

  const [isContactEntered, setIsContactEntered] = useState(false);

  const [Notify, SetNotify] = useState({
    isOpen: false,
    message: "",
    type: "info",
    title: "Information",
  });
  const handleContactNumber = (newValue) => {
    if (newValue === "") {
      setIsContactEntered(false);
    } else {
      setIsContactEntered(true);
    }

    setIsValidNumber(isValidPhoneNumber(newValue));
    setcontactNumber(newValue);
    setIsContactEntered(true);
  };

  const FirstName = useRef(null);
  const LastName = useRef(null);
  const Email = useRef(null);
  const MsgTitle = useRef(null);
  const Message = useRef(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await addDoc(collection(FireBaseDB, "ConsulationRequests"), {
        First_Name: FirstName.current.value,
        Last_Name: LastName.current.value,
        Email: Email.current.value,
        Contact_Number: contactNumber,
        Message_Title: MsgTitle.current.value,
        Message: Message.current.value,
      });
      FirstName.current.value = "";
      LastName.current.value = "";
      Email.current.value = "";
      setcontactNumber("");
      MsgTitle.current.value = "";
      Message.current.value = "";
      setIsValidEmail(false);
      setIsValidNumber(false);
      SetNotify({
        isOpen: true,
        message: "We've received your message and we will get back to you within 24 hours",
        type: "success",
        title: "Thank You!",
      });
    } catch (err) {
      SetNotify({
        isOpen: true,
        message: err.message,
        type: "error",
        title: "Oops! Something went wrong.",
      });
    }
  };

  const emailValidation = (event) => {
    var pattern = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/;

    if (event.target.value === "") {
      setIsMailEntered(false);
    } else {
      setIsMailEntered(true);
    }

    if (event.target.value.match(pattern)) {
      setIsValidEmail(true);
    } else {
      setIsValidEmail(false);
    }
  };

  return (
    <section className={classes.ContactUs} id="ContactUs" ref={props.reference}>
      <div className={style.TitleHolder}>
        <h3 className={style.Title}>Contact Us</h3>
      </div>
      <div className={classes.GridContainer}>
        <div className={classes.contactUsForm}>
          <div className={classes.card}>
            <div className={classes.container}>
              <Avatar sx={{ width: 100, height: 100 }} className={classes.ContactUSCardIcon}>
                <FontAwesomeIcon icon={faEnvelope} fontSize={35} />
              </Avatar>
              <Typography variant="h5" color="success">
                Send us a message
              </Typography>
              <form onSubmit={handleSubmit} name="consulationRequest">
                <FormControl variant="filled" fullWidth margin="normal" required>
                  <div className={classes.FullNameDiv}>
                    <TextField
                      sx={{ width: "49%" }}
                      label="First Name"
                      type="text"
                      id="firstName"
                      name="firstName"
                      margin="normal"
                      className={classes.Name}
                      inputRef={FirstName}
                      required
                    />
                    <TextField
                      sx={{ width: "49%" }}
                      type="text"
                      label="Last Name"
                      id="lastName"
                      name="lastName"
                      margin="normal"
                      className={classes.Name}
                      inputRef={LastName}
                      required
                    />
                  </div>
                  <TextField
                    fullWidth
                    type="email"
                    label="Email"
                    id="email"
                    margin="normal"
                    inputRef={Email}
                    onChange={emailValidation}
                    InputProps={{
                      endAdornment: isMailEntered && (
                        <InputAdornment position="end">
                          {isValidEmail ? (
                            <FontAwesomeIcon icon={faCircleCheck} fontSize={20} style={{ color: "#33CC00" }} />
                          ) : (
                            <FontAwesomeIcon icon={faCircleXmark} fontSize={20} style={{ color: "#ff5555" }} />
                          )}
                        </InputAdornment>
                      ),
                    }}
                    required
                  />

                  <MuiTelInput
                    value={contactNumber}
                    onChange={handleContactNumber}
                    InputProps={{
                      endAdornment: isContactEntered && (
                        <InputAdornment position="end">
                          {isValidNumber ? (
                            <FontAwesomeIcon icon="fa-solid fa-badge-check" color="success" />
                          ) : (
                            <FontAwesomeIcon icon="fa-solid fa-circle-xmark" color="error" />
                          )}
                        </InputAdornment>
                      ),
                    }}
                    defaultCountry="IN"
                    margin="normal"
                  />
                  <TextField
                    fullWidth
                    type="text"
                    label="Title"
                    id="MessageTitle"
                    name="MessageTitle"
                    inputRef={MsgTitle}
                    margin="normal"
                    required
                  />
                  <TextField
                    type="text"
                    label="Message"
                    multiline
                    id="Message"
                    name="Message"
                    rows={5}
                    inputRef={Message}
                    margin="normal"
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    margin="normal"
                    endIcon={<FontAwesomeIcon icon={faCircleRight} />}
                  >
                    Send
                  </Button>
                </FormControl>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Notification Notify={Notify} SetNotify={SetNotify} />
    </section>
  );
}
