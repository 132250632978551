import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

export default function Footer() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "auto",
        height: "50px",
        backgroundColor: "primary.main",
        color: "#FFF",
        justifyContent: "center",
      }}
    >
      <Typography>Copyright © 2023 Shadow-Interiors. All rights reserved.</Typography>
    </Box>
  );
}
