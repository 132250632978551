import { Alert, AlertTitle, Slide, Snackbar } from "@mui/material";
import React from "react";

export default function Notification(props) {
  const { Notify, SetNotify } = props;

  const HandleClose = (event, reason) => {
    SetNotify({
      ...Notify,
      isOpen: false,
    });
  };

  return (
    <Slide direction="left" in={Notify.isOpen}>
      <Snackbar
        open={Notify.isOpen}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        sx={{ mt: 8 }}
        onClose={HandleClose}
      >
        <Alert severity={Notify.type} onClose={HandleClose}>
          <AlertTitle>{Notify.title}</AlertTitle>
          {Notify.message}
        </Alert>
      </Snackbar>
    </Slide>
  );
}
