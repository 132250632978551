import React from "react";
import classes from "./Objectives.module.css";
import { faBullseye, faEye, faMountain } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@mui/material";
import style from "../Content/Content.module.css";

export default function Objectives(props) {
  return (
    <section id="Objectives" ref={props.reference}>
      <div className={style.TitleHolder}>
        <h3 className={style.Title} id="Title">
          Our Objectives
        </h3>
      </div>

      <div className={classes.SubSection2}>
        <div className={classes.GoalsBox}>
          <div className={classes.IconMessageBox}>
            <FontAwesomeIcon icon={faEye} className={classes.Icon} />
            <div className={classes.IconMessage}>
              <Typography variant="h5">Vision</Typography>
              <p>
                Our vision is to shape spaces with “timeless designs” and want to be a globally recognized professional
                interior design company, providing solutions in the field of high-end corporate, commercial &
                residential interiors.
              </p>
            </div>
          </div>

          <div className={classes.IconMessageBox}>
            <FontAwesomeIcon icon={faBullseye} className={classes.Icon} />
            <div className={classes.IconMessage}>
              <Typography variant="h5">Mission</Typography>
              <p>
                Our mission is to positively influence the lives of all customers with whom we have the pleasure of
                working, and leave a lasting impression on their minds and imagination. Our designs must inspire and
                elevate the hearts and minds of all those who lay eyes on it. Our long-term goals are always in line
                with our core values. Our mission is to always go one step further – to offer an exclusive customer
                service to our clients.
              </p>
            </div>
          </div>

          <div className={classes.IconMessageBox}>
            <FontAwesomeIcon icon={faMountain} className={classes.Icon} />
            <div className={classes.IconMessage}>
              <Typography variant="h5">Values</Typography>
              <p>
                Our Values are to continually improve and invest in our project quality, working methods and management
                systems and in our professional staff within our specialist disciplines through creativity and
                innovation in order to ensure our continual development as a quality driven design consultancy services
                business to our qualified Clients for projects in both Interiors and Architecture.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
