import React from "react";
import classes from "./Content.module.css";

const itemData = [
  {
    img2: require("../../images/LAYOUT_2BHK.jpg"),
    title: "LAYOUT 2BHK",
  },
  {
    img2: require("../../images/LAYOUT_3BHK.jpg"),
    title: "LAYOUT 3BHK",
  },
  {
    img2: require("../../images/LAYOUT_3.5BHK.jpg"),
    title: "LAYOUT 3.5BHK",
  },
  {
    img2: require("../../images/MASTER_BEDROOM.jpg"),
    title: "MASTER BEDROOM",
  },
  {
    img2: require("../../images/TV_UNIT.jpg"),
    title: "TV Unit",
  },
  {
    img2: require("../../images/DINNING.jpg"),
    title: "DINNING",
  },
  {
    img2: require("../../images/KITCHEN.jpg"),
    title: "KITCHEN",
  },
  {
    img2: require("../../images/GUEST_BEDROOM.jpg"),
    title: "GUEST BEDROOM",
  },
  {
    img2: require("../../images/BAR.jpg"),
    title: "BAR",
  },
  {
    img2: require("../../images/DINNING_DECOR.jpg"),
    title: "DECOR",
  },
  {
    img2: require("../../images/WHITE_BEDROOM.jpg"),
    title: "WHITE BEDROOM",
  },
  {
    img2: require("../../images/LIVING_ROOM.jpg"),
    title: "LIVING ROOM",
  },
];

export default function Content(props) {
  return (
    <section className={classes.Content} id="Content" ref={props.reference}>
      <div className={classes.TitleHolder}>
        <h3 className={classes.Title}>Our Designs</h3>
      </div>
      <div className={classes.Holder}>
        <div className={classes.ImageList}>
          {itemData.map((item) => (
            <div key={item.title}>
              <img
                src={`${item.img2}?w=248&fit=crop&auto=format`}
                srcSet={`${item.img2}?w=248&fit=crop&auto=format&dpr=2 2x`}
                alt={item.title}
                loading="lazy"
                className={classes.imageCard}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
