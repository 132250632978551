import "./App.css";
import { createTheme, ThemeProvider } from "@mui/material";
import Headers from "./Components/Header/Header";
import Content from "./Components/Content/Content";
import ContactUs from "./Components/ContactUs/ContactUs";
import { AppBar, Toolbar } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useRef } from "react";
import AboutUs from "./Components/AboutUs/AboutUs";
import Logo from "./images/Logos/Shadow_Interiors_Transperent-Logo.svg";
import Objectives from "./Components/Objectives/Objectives";
import Footer from "./Components/Footer/Footer";

const Theme = createTheme({
  typography: {
    fontFamily: "K2D",
  },
  palette: {
    primary: {
      main: "#30628B",
    },
  },
});

const navItems = ["Home", "Our Work", "Objectives", "About Us", "Contact Us"];

function App() {
  const homeRef = useRef(null);

  const ourWork = useRef(null);

  const contactUsRef = useRef(null);

  const aboutUs = useRef(null);

  const objectives = useRef(null);

  const handleClick = (item) => {
    if (item === "Contact Us") {
      contactUsRef.current?.scrollIntoView({ behavior: "smooth" });
    } else if (item === "Home") {
      homeRef.current?.scrollIntoView({ behavior: "smooth" });
    } else if (item === "Our Work") {
      ourWork.current?.scrollIntoView({ behavior: "smooth" });
    } else if (item === "About Us") {
      aboutUs.current?.scrollIntoView({ behavior: "smooth" });
    } else if (item === "Objectives") {
      objectives.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="SectionContainer">
      <AppBar
        component="nav"
        className="NavBar"
        style={{ background: "rgba(12, 11, 11, 0.123)", boxShadow: "none", color: "#fff" }}
        sx={{ display: { xs: "none", sm: "block", textTransform: "uppercase" } }}
      >
        <Toolbar>
          <Typography
            variant="h6"
            fontFamily="K2D"
            sx={{ flexGrow: 1, display: { xs: "none", sm: "block", textTransform: "uppercase" } }}
          >
            <img src={Logo} alt="Shadow Interiors Logo" height="50px" />
          </Typography>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            {navItems.map((item) => (
              <Button
                key={item}
                sx={{ color: "#fff", fontWeight: 600, fontFamily: "K2D" }}
                onClick={() => handleClick(item)}
              >
                {item}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <ThemeProvider theme={Theme}>
        <Headers reference={homeRef} />
        <Content reference={ourWork} />
        <Objectives reference={objectives} />
        <AboutUs reference={aboutUs} />
        <ContactUs reference={contactUsRef} />
        <Footer />
      </ThemeProvider>
    </div>
  );
}

export default App;
